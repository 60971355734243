// fr.js
export const locales = {
    toolbar: {
      today: 'Aujourd\'hui',
      month: 'Mois',
      week: 'Semaine',
      day: 'Jour',
      date: 'Date',
      back: 'Précédent',
      next: 'Suivant',
    },
  };
  