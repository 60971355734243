import React, { useState, useEffect, useCallback } from "react";
import { Button, Modal, Form, Table } from "react-bootstrap";
import axios from "axios";
import "./styles.css";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Gererclient = () => {
  const [clientsParent, setClientsParent] = useState([]);
  const [clientsFils, setClientsFils] = useState([]);
  const [workers, setWorkers] = useState([]);
  const [selectedWorker, setSelectedWorker] = useState("");
  const [selectedWorkerLogin, setSelectedWorkerLogin] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [newClient, setNewClient] = useState({
    raisonSocial: "",
    tel: "",
    gouvernorat: "",
    delegation: "",
    adresse: "",
    classement: "",
    latitude: "",
    longitude: "",
    idClientPar: "",
    merch: "",
    clientCode: ""
  });
  const [selectedClient, setSelectedClient] = useState(null);

  const fetchWorkers = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}Merchandiser/GetMerchandisers`);
      console.log("Workers retrieved:", response.data);
      setWorkers(response.data);
    } catch (error) {
      console.error("Error retrieving merchandisers:", error);
    }
  }, []);

  const fetchClientsParent = useCallback(async (workerId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}Merchandiser/GetClientsParByMerch/${workerId}`);
      console.log("Parent clients retrieved:", response.data);
      setClientsParent(response.data);
    } catch (error) {
      console.error("Error retrieving parent clients:", error);
    }
  }, []);

  const fetchClientsfils = useCallback(async (workerId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}Merchandiser/GetClientsByMerch/${workerId}`);
      console.log("Child clients retrieved:", response.data);
      setClientsFils(response.data);
    } catch (error) {
      console.error("Error retrieving child clients:", error);
    }
  }, []);

  useEffect(() => {
    fetchWorkers();
  }, [fetchWorkers]);

  useEffect(() => {
    if (selectedWorker) {
      fetchClientsParent(selectedWorker);
      fetchClientsfils(selectedWorker);
    }
  }, [selectedWorker, fetchClientsfils, fetchClientsParent]);

  const handleShowModal = () => {
    setNewClient({
      raisonSocial: "",
      tel: "",
      gouvernorat: "",
      delegation: "",
      adresse: "",
      classement: "",
      latitude: "0",
      longitude: "0",
      idClientPar: "",
      merch: selectedWorkerLogin,
      clientCode: ""
    });
    setShowModal(true);
  };

  const handleWorkerChange = (e) => {
    const selectedId = e.target.value;
    setSelectedWorker(selectedId);
    const worker = workers.find((w) => w.user_id === parseInt(selectedId));
    if (worker) {
      setSelectedWorkerLogin(worker.login);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!newClient.idClientPar) {
      alert("Veuillez sélectionner un client parent.");
      return;
    }

    const clientData = {
      ...newClient,
      latitude: newClient.latitude || "0",
      longitude: newClient.longitude || "0",
      idClientPar: newClient.idClientPar || null,
      merch: selectedWorkerLogin
    };

    console.log("Data sent for new client:", clientData);

    try {
      const response = await axios.post(`${API_BASE_URL}Merchandiser/AddClient`, clientData, {
        headers: { "Content-Type": "application/json" }
      });
      if (response.status === 200) {
        console.log("Client added successfully", response.data);
        setShowModal(false);
        fetchClientsfils(selectedWorker);
      } else {
        console.error("Error adding client:", response.data);
      }
    } catch (error) {
      console.error("Error adding client:", error.response?.data || error);
    }
  };

  const handleEdit = (client) => {
    console.log("Editing client:", client); // Check the data being passed
    setSelectedClient(client); // Set entire client object, not just IdClient
    setEditModal(true);
  };
  
  const handleUpdate = async (e) => {
    e.preventDefault();
  
    if (!selectedClient || !selectedClient.idClient) {
      alert("Client ID is missing");
      return;
    }
  
    console.log("Updating client with idClient:", selectedClient.idClient);
  
    try {
      const formData = new URLSearchParams();
      formData.append("Tel", selectedClient.tel);
      formData.append("Classement", selectedClient.classement);
      formData.append("Latitude", selectedClient.latitude || "0");
      formData.append("Longitude", selectedClient.longitude || "0");
      formData.append("Rep_0", selectedClient.rep_0 || "0");
      formData.append("Rep_1", selectedClient.rep_1 || "0");
  
      const response = await axios.put(
        `${API_BASE_URL}Merchandiser/UpdateClient?IdClient=${selectedClient.idClient}`,
        formData,
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      );
  
      if (response.status === 200) {
        console.log("Client updated successfully", response.data);
        setEditModal(false);
        fetchClientsfils(selectedWorker);
      } else {
        console.error("Error updating client:", response.data);
      }
    } catch (error) {
      console.error("Error updating client:", error.response?.data || error);
      alert(error.response?.data?.message || "Failed to update client.");
    }
  };
  
  
  return (
    <div>
      <Form.Group controlId="formWorker">
        <Form.Label>Merchandiseur</Form.Label>
        <Form.Control as="select" value={selectedWorker} onChange={handleWorkerChange}>
          <option value="">Sélectionnez un merchandiseur</option>
          {workers.map((worker) => (
            <option key={worker.user_id} value={worker.user_id}>
              {worker.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      {selectedWorker && clientsFils.length > 0 && (
        <div className="table-container">
          <h5>Clients Fils pour le merchandiser sélectionné</h5>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Code Client</th>
                <th>Raison Sociale</th>
                <th>Gouvernorat</th>
                <th>Téléphone</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {clientsFils.map((client) => (
                <tr key={client.idClient}>
                  <td>{client.clientCode}</td>
                  <td>{client.raisonSocial + " " + client.adresse}</td>
                  <td>{client.gouvernorat}</td>
                  <td>{client.tel}</td>
                  <td>
                    <Button
                      variant="info"
                      onClick={() => handleEdit(client)}
                      className="me-2"
                    >
                      <i className="bi bi-pencil" style={{ color: "white" }}></i>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}

      <div className="d-flex justify-content-end mb-3">
        <Button variant="primary" onClick={handleShowModal}>
          Ajouter un client
        </Button>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un nouveau client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            {/* Other form fields */}
            <Button variant="primary" type="submit">
              Enregistrer
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={editModal} onHide={() => setEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Modifier le client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form onSubmit={handleUpdate}>
          <Form.Group controlId="editTel">
                  <Form.Label>Téléphone</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedClient?.tel || ""}
                    onChange={(e) =>
                      setSelectedClient({ ...selectedClient, tel: e.target.value })
                    }
                  />
                </Form.Group>

                <Form.Group controlId="editLongitude">
                  <Form.Label>Longitude</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedClient?.longitude || ""}
                    onChange={(e) =>
                      setSelectedClient({ ...selectedClient, longitude: e.target.value })
                    }
                  />
                </Form.Group>
                <Form.Group controlId="editLatitude">
                  <Form.Label>Latitude</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedClient?.latitude || ""}
                    onChange={(e) =>
                      setSelectedClient({ ...selectedClient, latitude: e.target.value })
                    }
                  />
                </Form.Group>
                <Form.Group controlId="editClassement">
                  <Form.Label>Classement</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedClient?.classement || ""}
                    onChange={(e) =>
                      setSelectedClient({ ...selectedClient, classement: e.target.value })
                    }
                  />
                </Form.Group>
                <Form.Group controlId="editRep_0">
                  <Form.Label>Reprenstant 1</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedClient?.rep_0 || ""}
                    onChange={(e) =>
                      setSelectedClient({ ...selectedClient, rep_0: e.target.value })
                    }
                  />
                </Form.Group>
                <Form.Group controlId="editRep_1">
                  <Form.Label>Representant 2</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedClient?.rep_1 || ""}
                    onChange={(e) =>
                      setSelectedClient({ ...selectedClient, rep_1: e.target.value })
                    }
                  />
                </Form.Group>

            {/* Other fields for editing */}
            <Button variant="primary" type="submit">
              Mettre à jour
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Gererclient;
