import React, { useState, useEffect } from "react";
import axios from "axios";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/fr";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import CustomToolbar from "./CustomToolbar";
import "./ReactBigCalendar.css";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

moment.locale("fr");
const localizer = momentLocalizer(moment);

const Event = ({ event }) => (
  <span className="rbc-event">
    <span className="dot"></span>
    <span>
      {moment(event.start).format("HH:mm")} - {event.raisonSocial}
    
    </span>
  </span>
);

const ReactBigCalendar = ({ events }) => {
  const [newEvent, setNewEvent] = useState({
    id: null,
    client: "",
    worker: "",
    gouvernorat: "",
    missionDescription: "",
    date: moment().format("YYYY-MM-DD"),
    time: moment().format("HH:mm:ss"),
    raisonSocial: "",
    adresse: "",
  });

  const [merchandisers, setMerchandisers] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await fetchWorkers();
    };

    fetchData();
  }, []);

  const fetchWorkers = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}Merchandiser/GetMerchandisers`
      );
      setMerchandisers(response.data);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des travailleurs:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setNewEvent({
      id: null,
      client: "",
      worker: "",
      gouvernorat: "",
      missionDescription: "",
      date: moment().format("YYYY-MM-DD"),
      time: moment().format("HH:mm:ss"),
      raisonSocial: "",
      adresse: "",
    });
  };

  const handleViewEvent = (event) => {
    setNewEvent({
      id: event.id,
      client: event.client,
      worker: event.worker,
      gouvernorat: event.gouvernorat,
      missionDescription: event.missionDescription,
      date: moment(event.start).format("YYYY-MM-DD"),
      time: moment(event.start).format("HH:mm:ss"),
      raisonSocial: event.raisonSocial,
      adresse: event.adresse,
    });
    setShowModal(true);
  };

  return (
    <Container>
      <Calendar
        localizer={localizer}
        events={events.map((event) => ({
          ...event,
          start: new Date(`${event.date}T${event.time}`),
          end: new Date(`${event.date}T${event.time}`),
        }))}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 600 }}
        components={{
          event: (eventProps) => (
            <Event
              key={eventProps.event.id || eventProps.event.start}
              {...eventProps}
            />
          ),
          toolbar: CustomToolbar,
        }}
        onSelectEvent={(event) => handleViewEvent(event)}
      />

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Détails de la mission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formWorker">
              <Form.Label>Merchandiseur</Form.Label>
              <Form.Control
                type="text"
                value={newEvent.worker || ""}
                readOnly
              />
            </Form.Group>

            <Form.Group controlId="formClient">
              <Form.Label>Client</Form.Label>
              <Form.Control
                type="text"
                value={newEvent.client}
                readOnly
              />
            </Form.Group>

            <Form.Group controlId="formRaisonSocial">
              <Form.Label>Raison Sociale</Form.Label>
              <Form.Control
                type="text"
                value={newEvent.raisonSocial+" "+newEvent.adresse+" "+newEvent.gouvernorat}
                readOnly
              />
            </Form.Group>


            <Form.Group controlId="formMissionDescription">
              <Form.Label>Description de la mission</Form.Label>
              <Form.Control
                type="text"
                value={newEvent.missionDescription}
                readOnly
              />
            </Form.Group>

            <Form.Group controlId="formDate">
              <Form.Label>Date</Form.Label>
              <Form.Control
                type="date"
                value={newEvent.date}
                readOnly
              />
            </Form.Group>

            <Form.Group controlId="formTime">
              <Form.Label>Heure</Form.Label>
              <Form.Control
                type="time"
                value={newEvent.time}
                readOnly
              />
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default ReactBigCalendar;
