import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import "./styles.css";
import * as XLSX from "xlsx";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const RapportComponent = () => {
  const [date, setDate] = useState("");
  const [userId, setUserId] = useState("");
  const [rapports, setRapports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [workers, setWorkers] = useState([]);

  const fetchWorkers = useCallback(async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}Merchandiser/GetMerchandisers`
      );
      setWorkers(response.data);
    } catch (error) {
      setError(error);
    }
  }, []);

  useEffect(() => {
    fetchWorkers();
  }, [fetchWorkers]);

  const fetchRapports = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${API_BASE_URL}Rapporting/GetRapport`,
        { params: { date, user_id: userId } }
      );
      setRapports(response.data);
    } catch (error) {
      console.error("Error fetching rapports:", error);
      setError("Erreur lors de la récupération des rapports.");
    } finally {
      setLoading(false);
    }
  }, [date, userId]);

  useEffect(() => {
    fetchRapports();
  }, [fetchRapports]);

  const groupByDateAndClient = (rapports) => {
    const grouped = rapports.reduce((acc, rapport) => {
      const dateClientKey = `${new Date(
        rapport.missionDate
      ).toLocaleDateString()}-${rapport.raisonSocial}-${rapport.adresse}-${rapport.userName}`;
      if (!acc[dateClientKey]) {
        acc[dateClientKey] = [];
      }
      acc[dateClientKey].push(rapport);
      return acc;
    }, {});
    return grouped;
  };

  const groupedRapports = groupByDateAndClient(rapports);

  const handlePrint = () => window.print();

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(rapports);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Rapport Prix");
    XLSX.writeFile(workbook, "Rapports.xlsx");
  };

  const calculateAdjustedPrice = (prixB, contenanceA, contenanceB) => {
    if (contenanceB === 0) return 0; // Éviter division par zéro
    return (prixB / contenanceB) * contenanceA;
  };
  const calculateAdjustedPromoPrice = (prixPromo, contenanceA, contenanceB) => {
    if (contenanceB === 0) return 0; // Éviter division par zéro
    return (prixPromo / contenanceB) * contenanceA;
  };
  

  const calculateMarginRate = (prixRef, prixAjuste) => {
    if (prixRef === 0) return 0; // Éviter division par zéro
    return (prixAjuste / prixRef) *100;
  };
  const calculateMarginRatePromo = (prixRef, prixPromoAjuste, prixAjuste) => {
    if (prixRef === 0) return 0; // Éviter division par zéro
   return (prixPromoAjuste / prixRef)*100 
    ;
  };
  
  

  return (
    <div style={styles.container}>
      <h2 style={styles.header}>Rapport Prix</h2>
      <div className="no-print">
        <div style={styles.formContainer}>
          <div style={styles.formGroup}>
            <label style={styles.label}>Date:</label>
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              style={styles.input}
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>Marchandiseur:</label>
            <select
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              style={styles.input}
            >
              <option value="">Marchandiseur</option>
              {workers.map((worker) => (
                <option key={worker.user_id} value={worker.user_id}>
                  {worker.name}
                </option>
              ))}
            </select>
          </div>
          <button onClick={handlePrint} style={styles.button}>
            <i className="bi bi-printer"></i>
          </button>
          <button onClick={exportToExcel} style={styles.button}>
            Exporter <i className="bi bi-file-earmark-spreadsheet"></i>
          </button>
        </div>
      </div>
      {loading ? (
        <p style={styles.loading}>Loading...</p>
      ) : error ? (
        <p style={styles.error}>{error}</p>
      ) : Object.keys(groupedRapports).length === 0 ? (
        <p style={styles.empty}>Aucun rapport à afficher.</p>
      ) : (
        Object.keys(groupedRapports).map((dateClientKey) => {
          const [dateKey, clientKey, adresseKey] = dateClientKey.split("-");
          const groupedData = groupedRapports[dateClientKey];
          const groupedByArticle = groupedData.reduce((acc, rapport) => {
            if (!acc[rapport.article]) acc[rapport.article] = [];
            acc[rapport.article].push(rapport);
            return acc;
          }, {});

          return (
            <div key={dateClientKey} style={styles.tableContainer}>
              <h2 className="custom-heading">
                Mission {dateKey} | {clientKey} {adresseKey} |{" "}
                {groupedData[0].userName}
              </h2>
              <Table bordered className="custom-table">
                <thead>
                  <tr>
                    <th>Article</th>
                    <th>Marque</th>
                    <th>Contenance</th>
                    <th>Prix TTC</th>
                    <th>Prix TTC Promotionnel</th>
                    <th>Prix rapporté de meme contenance</th>
                    <th>Prix promo rapporté de meme contenance</th>
                    <th>Indice de prix (%)</th>
                    <th>Indice de prix Promotionnel (%)</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(groupedByArticle).map((articleKey) => {
                    const articles = groupedByArticle[articleKey];
                    const refArticle = articles[0]; // Article de référence

                    return (
                      <React.Fragment key={articleKey}>
                      {articles.map((rapport, index) => {
                        const prixAjuste = calculateAdjustedPrice(
                          rapport.prix,
                          refArticle.contenance,
                          rapport.contenance
                        );

                        const prixPromoAjuste = calculateAdjustedPromoPrice(
                          rapport.prixPromo,
                          refArticle.contenance,
                          rapport.contenance
                        );

                        const tauxIndice = calculateMarginRate(refArticle.prix, prixAjuste);
                        
                        const tauxPromoIndice = calculateMarginRatePromo(
                          refArticle.prix,
                          prixPromoAjuste,
                          prixAjuste
                        );

                        return (
                          <tr key={`${rapport.idLigne}-${index}`}>
                            {index === 0 && (
                              <td rowSpan={articles.length}>{rapport.article}</td>
                            )}
                            <td>{rapport.marque}</td>
                            <td>{rapport.contenance}</td>
                            <td>{rapport.prix}</td>
                            <td>{rapport.prixPromo}</td>
                            <td>{prixAjuste.toFixed(2)}</td>
                            <td>{prixPromoAjuste.toFixed(2)}</td>
                            <td>{tauxIndice.toFixed(2)}%</td>
                            <td>{tauxPromoIndice.toFixed(2)}%</td>
                          </tr>
                        );
                      })}


                      </React.Fragment>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          );
        })
      )}
    </div>
  );
};

const styles = {
  container: {
    padding: "15px",
    fontFamily: "Arial, sans-serif",
  },
  header: {
    color: "#333",
    marginBottom: "10px",
    textAlign: "center",
  },
  formContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    marginRight: "15px",
  },
  label: {
    marginBottom: "5px",
    fontWeight: "bold",
  },
  input: {
    padding: "10px",
    borderRadius: "4px",
    border: "1px solid #ddd",
    width: "195px",
    boxSizing: "border-box",
  },
  button: {
    padding: "10px 20px",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "#d6eaf8",
    fontSize: "16px",
    marginLeft: "10px",
    height: "40px",
    marginTop: "30px",
    alignItems: "center",
  },
  loading: {
    fontSize: "16px",
    color: "#007bff",
    textAlign: "center",
  },
  error: {
    fontSize: "16px",
    color: "red",
    textAlign: "center",
  },
  empty: {
    fontSize: "16px",
    color: "#333",
    textAlign: "center",
  },
  tableContainer: {
    marginBottom: "20px",
  },
};


export default RapportComponent;
