import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { Button, Table, Modal, Form } from "react-bootstrap";

const Gererarticle = () => {
  const [clients, setClients] = useState([]);
  const [workers, setWorkers] = useState([]);
  const [selectedWorker, setSelectedWorker] = useState('');
  const [selectedClient, setSelectedClient] = useState('');
  const [articles, setArticles] = useState([]);
  const [idArticle, setIdArticle] = useState('');
  const [idClient, setIdClient] = useState('');
  const [codeMetier, setCodeMetier] = useState('');
  const [qteMin, setQteMin] = useState('');
  const [assortiments, setAssortiments] = useState([]);
  const [idAssortimentToUpdate, setIdAssortimentToUpdate] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [assortimentToDelete, setAssortimentToDelete] = useState(null);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const fetchClients = useCallback(async (workerId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}Merchandiser/GetClientsByMerch/${workerId}`);
      if (response.data && response.data.length > 0) {
        setClients(response.data);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des clients", error);
    }
  }, []);

  const fetchWorkers = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}Merchandiser/GetMerchandisers`);
      setWorkers(response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des marchandiseurs:", error);
    }
  }, []);

  const fetchArticles = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}RapportPrix/GetArticles`);
      setArticles(response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des articles", error);
    }
  }, []);

  const fetchAssortiments = async (idClient) => {
    try {
      const response = await axios.get(`${API_BASE_URL}Assortiment/GetAssortiments/${idClient}`);
      if (response.data && response.data.length > 0) {
        setAssortiments(response.data);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des assortiments", error);
    }
  };
  const getImagePath = (idArticle) => `assets/${idArticle}.jpg`;


  
  useEffect(() => {
    const loadData = async () => {
      await fetchWorkers();
    };
    loadData();
  }, [fetchWorkers]);

  useEffect(() => {
    if (selectedClient) {
      setIdClient(selectedClient);
      fetchAssortiments(selectedClient);
    }
  }, [selectedClient]);

  useEffect(() => {
    fetchArticles();
  }, [fetchArticles]);

  const addAssortiment = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}Assortiment/AddAssortiment`, {
        IdArticle: idArticle,
        IdClient: idClient,
        CodeMetier: codeMetier,
        QteMin:qteMin

      });
      if (response.status === 200) {
        fetchAssortiments(idClient);
        
        // Vider les champs après l'ajout
        setIdArticle('');
        setCodeMetier('');
        setQteMin('');
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout de l'assortiment", error);
    }
  };
  

  const updateAssortiment = async () => {
    try {
      const response = await axios.put(`${API_BASE_URL}Assortiment/UpdateAssortiment`, {
        IdAssortiment: idAssortimentToUpdate,
        CodeMetier: codeMetier,
        QteMin:qteMin,
      });
      if (response.status === 200) {
        setIdAssortimentToUpdate(null);
        fetchAssortiments(idClient);
        setShowModal(false);
        setCodeMetier('');
        setQteMin('');
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'assortiment", error);
    }
  };

  const deleteAssortiment = async (idAssortiment) => {
    try {
      const response = await axios.delete(`${API_BASE_URL}Assortiment/DeleteAssortiment/${idAssortiment}`);
      if (response.status === 200) {
        fetchAssortiments(idClient);
        setShowDeleteConfirmModal(false);  // Fermer le modal de confirmation
      }
    } catch (error) {
      console.error("Erreur lors de la suppression de l'assortiment", error);
    }
  };
  const handleDeleteClick = (idAssortiment) => {
    setAssortimentToDelete(idAssortiment);  // Définir l'assortiment à supprimer
    setShowDeleteConfirmModal(true);  // Afficher le modal de confirmation
  };

  return (
<div>
  <Form>
    <div className="row mb-4">
      <div className="col-md-6">
        <Form.Group controlId="formWorker">
          <Form.Label>Merchandiseur</Form.Label>
          <Form.Control
            as="select"
            value={selectedWorker}
            onChange={(e) => {
              setSelectedWorker(e.target.value);
              fetchClients(e.target.value);
            }}
          >
            <option value="">Sélectionnez un merchandiseur</option>
            {workers.map((worker) => (
              <option key={worker.user_id} value={worker.user_id}>
                {worker.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </div>

      {/* Client Dropdown */}
      <div className="col-md-6">
        <Form.Group controlId="formClient">
          <Form.Label>Client</Form.Label>
          <Form.Control
            as="select"
            value={selectedClient}
            onChange={(e) => setSelectedClient(e.target.value)}
          >
            <option value="">Sélectionner un Client</option>
            {clients.map((client) => (
              <option key={client.idClient} value={client.idClient}>
                {client.raisonSocial} {client.adresse}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </div>
    </div>
    <div className="row mb-4">
    <div className="col-md-4">
    <Form.Group controlId="formArticle">
      <Form.Label>Article</Form.Label>
      <Form.Control
        as="select"
        value={idArticle}
        onChange={(e) => setIdArticle(e.target.value)}
      >
        <option value="">Sélectionner un article</option>
        {articles.map((article) => (
          <option key={article.id} value={article.id}>
            {article.designation}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
    </div>
    <div className="col-md-3">
    <Form.Group controlId="formCodeMetier">
      <Form.Label>Code Métier</Form.Label>
      <Form.Control
        type="text"
        placeholder="Code Métier"
        value={codeMetier}
        onChange={(e) => setCodeMetier(e.target.value)}
      />
    </Form.Group>
    </div>
    <div className="col-md-3">
    <Form.Group controlId="formQteMin">
      <Form.Label>Quantité Minimale</Form.Label>
      <Form.Control
        type="text"
        placeholder="Quantité Min"
        value={qteMin}
        onChange={(e) => setQteMin(e.target.value)}
      />
    </Form.Group>
    </div>
    <div className="col-md-2">
    <Button variant="primary" onClick={addAssortiment} style={{ marginTop: '33px' }}>
    Ajouter article
  </Button>
</div>

    </div>
    {/* Display Assortiments Table */}
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Image</th>
          <th>Code Article</th>
          <th>Code à barre</th>
          <th>Désignation</th>
          <th>Code Métier</th>
          <th>Quantité Minimale</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {assortiments.map((assortiment) => (
          <tr key={assortiment.idAssortiment}>
             <td>
             <img src={getImagePath(assortiment.idArticle +'.jpg')}
             alt={''}
             style={{ width: '60px', height: '70px' }} />

            </td>
            <td>{assortiment.idArticle}</td>
            <td>{assortiment.ean}</td>
            <td>{assortiment.designation}</td>
            <td>{assortiment.codeMetier}</td>
            <td>{assortiment.qteMin}</td>
            <td>
              <Button
                variant="info"
                onClick={() => {
                  setIdAssortimentToUpdate(assortiment.idAssortiment);
                  setCodeMetier(assortiment.codeMetier);
                  setQteMin(assortiment.qteMin)
                  setShowModal(true);
                }}
              >
                <i className="bi bi-pencil" style={{ color: "white" }}></i>
              </Button>
              <Button
                variant="danger"
                onClick={() => handleDeleteClick(assortiment.idAssortiment)}
              >
                <i className="bi bi-trash"></i>
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  </Form>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Mettre à jour</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formUpdateCodeMetier">
            <Form.Label>Code Métier</Form.Label>
            <Form.Control type="text" placeholder="Code Métier" value={codeMetier}
             onChange={(e) => setCodeMetier(e.target.value)} />
          </Form.Group>
          <Form.Group controlId="formUpdateQteMin">
            <Form.Label>Quantité Minimale</Form.Label>
            <Form.Control type="text" placeholder="Quantité Min" value={qteMin}
             onChange={(e) => setQteMin(e.target.value)} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Annuler
          </Button>
          <Button variant="primary" onClick={updateAssortiment}>
            Enregistrer
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDeleteConfirmModal} onHide={() => setShowDeleteConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation de suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir supprimer cet article ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteConfirmModal(false)}>
            Annuler
          </Button>
          <Button variant="danger" onClick={() => deleteAssortiment(assortimentToDelete)}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Gererarticle;
